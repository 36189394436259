import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Login from "./pages/LoginPage";
import {ThemeProvider, CssBaseline, createTheme} from "@mui/material";
import Success from "./pages/SuccessPage";
import {createClient, SupabaseClient} from "@supabase/supabase-js";
import AppWrapper from "./AppWrapper";
import config from "./config";
import InvitePage from "./pages/InvitePage";

const darkTheme = createTheme({
  palette: { mode: 'dark' }
})

const supabase: SupabaseClient = createClient(config.supabase.apiUrl, config.supabase.apiKey);




function App() {

  const [session, setSession] = useState<any>(null);
  const [user, setUser] = useState<any>({status: "PENDING"});
  const [rank, setRank] = useState<number>(1);

  useEffect(() => {
    supabase.auth.getSession().then((session) => {

      const getRank = async () => {
        const {data, error}: any = await supabase
          .from('users')
          .select()

        if(data){ setRank(data[0].rank); }
      }

      getRank().then(() => {
        session.data.session?.user.user_metadata ? setUser({...session.data.session?.user.user_metadata, status: "AUTHENTICATED"}) : setUser({status: "NOT_AUTHENTICATED"})
      });
    })

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session)
      if(!session?.user){ setUser({status: "NOT_AUTHENTICATED"}) }
    });

  }, [])

  const handleLogout = async (nav: any) => {
    await supabase.auth.signOut();
    nav();
  }

  return (
      <ThemeProvider theme={darkTheme}>

        <CssBaseline/>
        <Router>
          <AppWrapper session={session} rank={rank} supabase={supabase} user={user} handleLogout={handleLogout}/>
          <Routes>
            <Route path={"/"} element={<Login supabase={supabase}/>}/>
            <Route path={"/dashboard"} element={<Success supabase={supabase} session={session}/>}/>
            <Route path={"/invite/*"} element={<InvitePage supabase={supabase}/>}/>
          </Routes>
        </Router>
      </ThemeProvider>
  );
}

export default App;
