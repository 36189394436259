import {Box} from "@mui/material";
import {ReactComponent as Arrow} from '../../imgs/arrow.svg';

const RetroDefaultPage = () => {
  return (
    <Box sx={{width: "calc(100vw - 400px)", minHeight: "calc(100vh)"}}>
      <Arrow fill={"rgba(125,125,125,1)"} width={200}/>
    </Box>
  )
}

export default RetroDefaultPage;