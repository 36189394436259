import {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {acceptInvite} from "../api/actions/RetroAction";
import {SupabaseClient} from "@supabase/supabase-js";

interface Props {
  supabase: SupabaseClient
}


const InvitePage = ({supabase}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState("")

  useEffect(() => {

    const trimmed = location.pathname.replace("/invite/", "");

    // TODO need to add call to backend to add person to link.
   acceptInvite(supabase, trimmed).then((result) => {
     if(result){
       navigate('/dashboard', {state: {url: trimmed}})
     }else{
       setError("This is not the invite you're looking for!")
     }
   });

  }, [])
  return (<div>{error}</div>)
}

export default InvitePage;