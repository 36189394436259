import {Grid, Step, StepLabel, Stepper} from "@mui/material";
import RetroThoughtContainer from "./RetroThoughtContainer";
import React from "react";
import RetroWritingReflectionsPage from "./RetroWritingReflectionsPage";
import RetroOrganizingPage from "./RetroOrganizingPage";
import RetroResultPage from "./RetroResultPage";
import RetroVotingPage from "./RetroVotingPage";


const Retro = ({activeStep}: any) => {

  const steps = ['Write Reflections', 'Organize Them', 'Vote on Them', "Talk about Them"];


  const getActivePage = () => {
    switch(activeStep){
      case 0: return <RetroWritingReflectionsPage/>
      case 1: return <RetroOrganizingPage/>
      case 2: return <RetroVotingPage/>
      case 3: return <RetroResultPage/>
    }
  }

  return (<>
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item wrap={"wrap"}>
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      {getActivePage()}
    </Grid></>)
}

export default Retro;