import React, {useEffect, useState} from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Step,
  StepLabel,
  Stepper, Typography
} from "@mui/material";
import {SupabaseClient} from "@supabase/supabase-js";
import {acceptInvite, addRetro, getAllRetros, RetroObject} from "../api/actions/RetroAction";
import AddIcon from '@mui/icons-material/Add';
import {useNavigate, useLocation} from "react-router-dom";
import NewRetroDialog from "../dialogboxes/NewRetroDialog";
import RetroCard from "./RetroCard";
import Retro from './retro/Retro';
import {enqueueSnackbar} from "notistack";
import RetroDefaultPage from "./retro/RetroDefaultPage";

interface Props {
  supabase: SupabaseClient,
  session: any
}

const Success = ({supabase}: Props) => {
  const [retros, setRetros] = useState<any[]>([]);
  const [currentRetro, setCurrentRetro] = useState<string>("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [retroViewOpen, setRetroViewOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const location = useLocation()

  useEffect(() => {
    getAllRetros(supabase, setRetros).then((result) => {

    });
    if(location.state?.url){
      setCurrentRetro(location.state.url);
    }
  }, [])

  const handleAddRetro = (title: string) => {
    const handleRetroState = (data: any) => setRetros([...data, ...retros])

    addRetro({title: title}, supabase, handleRetroState).then((uuid: any) => {
      console.log(uuid);
    })
    setDialogOpen(false);
    setRetroViewOpen(false);
  }

  const handleInvitePeople = () => {
    navigator.clipboard.writeText(`https://lucidretro.dev/invite/${currentRetro}`)
    enqueueSnackbar("Invite link has been copied!", {variant: "success"});
  }

  const handleRetroSelect = (id: string) => {
    setCurrentRetro(id);
    setRetroViewOpen(false);
  }

  return (
    <Box sx={{height: "calc(100vh - 80px)"}}>
      <Grid container wrap={"nowrap"} justifyContent={"center"}>
        <Grid item>
          <Drawer
            variant="temporary"
            open={retroViewOpen || currentRetro === ""}
            onClose={() => setRetroViewOpen(false)}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <Grid
              item
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
              wrap={"nowrap"}
              sx={{maxHeight: "calc(100vh)", padding: "0 5px"}}
            >
              <Grid item sx={{paddingTop: "5px"}}>My Retros</Grid>
              <Divider flexItem sx={{m: "5px 0px 10px 0px"}}/>
              <Grid item><Button onClick={() => setDialogOpen(true)} fullWidth size={"small"} variant={"outlined"} startIcon={<AddIcon/>}>Add Retro</Button></Grid>
              <Divider flexItem sx={{m: "10px 0px 10px 0px"}}/>
              <Grid
                item
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                wrap={"nowrap"}
                sx={{maxHeight: "calc(100vh)", overflowY: "auto", overflowX: "hidden", paddingBottom: "15px", paddingRight: "7px"}}
              >
              {retros.map((x: RetroObject) => (<RetroCard onClick={handleRetroSelect} retro={x}/>))}
              </Grid>
            </Grid>
          </Drawer>
        </Grid>
        <Grid item sx={{padding: "10px 15px 0px 15px"}}>
          <Paper elevation={1} sx={{padding: "15px 0 0 15px", minHeight: "calc(100vh-400px)"}}>
            <Paper onClick={() => setRetroViewOpen(true)} elevation={4}
                   sx={{
                     position: "absolute",
                     padding: "5px 10px",
                     marginLeft: "-37px",
                     top: 200,
                     zIndex: 5,
                     textAlign: "center",
                     left: 0,
                     cursor: "pointer",
                     transform: "rotate(90deg)",
                     '&&:hover': { backgroundColor: "rgba(150,150,150, 0.1)" }
                   }}>
              <Typography>View Retros</Typography>
            </Paper>
            {currentRetro !== '' &&
            <Paper elevation={2} style={{position: "absolute", padding: "5px 6px", marginRight: "15px", width: "150px", zIndex: 5, textAlign: "center"}} >
              <Button onClick={handleInvitePeople} startIcon={<AddIcon/>} size={"small"}>Invite Peeps</Button>
            </Paper>}
            {currentRetro !== '' ? <Retro activeStep={activeStep}/> : <RetroDefaultPage/>}
          </Paper>
        </Grid>
      </Grid>
      <NewRetroDialog open={dialogOpen} handleSubmit={handleAddRetro} handleClose={() => setDialogOpen(false)}/>
    </Box>
  );
}

export default Success;