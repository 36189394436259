import RetroThoughtContainer from "./RetroThoughtContainer";
import {Grid} from "@mui/material";
import React from "react";

const RetroWritingReflectionsPage = () => {
  return(
    <Grid item container justifyContent="center" wrap={"nowrap"} sx={{paddingTop: "10px"}}>
      <RetroThoughtContainer title={"Good 🌝"} color={"green"} subtext={"Things you're generally happy about"}
                             selfCards={[]} otherCards={[]}/>
      <RetroThoughtContainer title={"Can Use Improvement 🤔"} color={"yellow"}
                             subtext={"Things that are okay, but could be better"} selfCards={[]}
                             otherCards={[]}/>
      <RetroThoughtContainer title={"Can Take a Hike 😔"} color={"red"}
                             subtext={"Things that you're unhappy about"} selfCards={[]} otherCards={[]}/>
    </Grid>
  )
}

export default RetroWritingReflectionsPage