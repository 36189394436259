import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import {useState} from "react";

const NewRetroDialog = ({open, handleClose, handleSubmit}: any) => {

  const [title, setTitle] = useState("")

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Subscribe</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Here we will describe what we want for our retrospective meeting.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label={"Title for retro"}
          fullWidth
          variant="standard"
          value={title}
          onChange={(event => setTitle(event.target.value))}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(title)}>Submit</Button>
      </DialogActions>
   </Dialog>
  )
}

export default NewRetroDialog;