import {useNavigate, useLocation } from "react-router-dom";
import {Box} from "@mui/material";
import {Session} from "@supabase/supabase-js";

const Login = ({supabase}: any) => {
  const navigate = useNavigate();
  const location = useLocation();

  supabase.auth.onAuthStateChange(async (event: string, session: Session)=> {
    console.log("Auth State has changed:", event);

    // Handle all actions
    if(event === "SIGNED_OUT"){
      navigate('/')
    }else if(event === "SIGNED_IN"){
      navigate('/dashboard')
    }

    // Redirect if there's a user
    if(session.user){
      navigate('/dashboard');
    }
  });

  return (
    <Box sx={{height: "calc(100vh - 80px)", padding: "15px", textAlign: "center"}}>
      Put information here explaining what a retro is. Probably has pictures
    </Box>
  );
}

export default Login;