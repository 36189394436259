import {SupabaseClient, UserResponse} from "@supabase/supabase-js";
import {enqueueSnackbar} from "notistack";

export interface RetroObject {
  id: string,
  facilitator: string,
  title: string,
  users: string[],
  forced_anonymous: boolean,
  created_at: string,
  max_votes: number,
  stage: number
}

export interface InputRetro {
  title: string,
}

export const addTestRetro = async (supabase: SupabaseClient, setter: (object: any) => void) => {
  const {data, error} = await supabase
    .from('retro')
    .insert({title: "hello world", users: []})
    .select("*");

  return new Promise((resolve, reject) => {
    if (error) {
      enqueueSnackbar("Error: Forbidden. You ain't suppose to be here dawg", {variant: "error"});
      reject(error);
    } else {
      enqueueSnackbar("Success! Added retro!", {variant: "success"});
      setter(data);
      resolve(data);
    }
  })
}

export const addRetro = async (params: InputRetro, supabase: SupabaseClient, setter: (object: any) => void) => {
  const {data, error} = await supabase
    .from('retro')
    .insert(params)
    .select("*");

  if (error) enqueueSnackbar("Error: Forbidden. You ain't suppose to be here dawg", {variant: "error"});
  else {
    enqueueSnackbar("Success! Added retro!", {variant: "success"});
    setter(data);
  }
}

export const getAllRetros = async (supabase: SupabaseClient, setter: (object: any) => void) => {
  const {data} = await supabase
    .from('retro')
    .select();

  setter(data);
}

export const checkIfFacilitator = async (supabase: SupabaseClient, uuid: string) => {
  const user: any = undefined;
  const {data, error} = await supabase
    .from('retro')
    .select('*')
    .contains('facilitator', user);

  return new Promise((resolve: any, reject: any) => {
    if (!data) resolve(false);
    return;
    if (data?.filter(x => x.uuid === uuid)) {
      resolve(true)
    } else {
      resolve(false)
    }
  });
}

export const acceptInvite = async (supabase: SupabaseClient, retroID: string) => {
  // First, fetch the current users array for the given retroID
  const {data: retroData, error: retroError} = await supabase
    .from('retro')
    .select('users')
    .match({id: retroID})
    .single();

  if (retroError) {
    console.error(retroError);
    return new Promise((res: any, rej: any) => {
      res(false);
    });
  }

  // Extract the current users array from the fetched data
  const {users} = retroData;

  // Then, update the users array by appending the authenticated user's UUID
  supabase.auth.getUser()
    .then(async ({data}: UserResponse) => {
      const userUUID = data.user?.id // get the authenticated user's UUID
      const uniqueUsers = [...new Set([...users, userUUID])];
      const {data: updateData, error: updateError} = await supabase
        .from('retro')
        .update({users: uniqueUsers})
        .match({id: retroID});

      if (updateError) {
        console.error(updateError);
        return new Promise((res: any, rej: any) => {
          res(false);
        });
      }

      return new Promise((res: any, rej: any) => {
        res(updateData);
      });
    });

  return new Promise((res: any, rej: any) => {
    res(false);
  });
}