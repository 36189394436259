import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import React, {useEffect, useRef, useState} from "react";
import {Avatar, Box, Card, CardHeader, Skeleton, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {ThemeSupa} from "@supabase/auth-ui-shared";
import {Auth} from "@supabase/auth-ui-react";
import RankType from "./RankType";


const AppHeader = ({supabase, rank, session, user, handleLogout, isAdmin}: any) => {

  const navigate = useNavigate();
  const location = useLocation();

  const avatarRef = useRef();
  const defaultHeader = <CardHeader
    avatar={
      <Skeleton variant="circular" width={40} height={40} />
    }
    title={<Skeleton variant="text" sx={{fontSize: '1rem', maxWidth: "200px"}}/>}
    subheader={<Skeleton variant="text" sx={{fontSize: '1rem', maxWidth: "150px"}}/>}
  />;


  const [subtitle, setSubtitle] = useState(defaultHeader);


  const getRank = () => {
    switch (rank) {
      case RankType.Restricted:
        return {color: "#ff9100", name: "Restricted"}
      case RankType.NormalUser:
        return {color: "lightblue", name: "Normal User"}
      case RankType.VIPUser:
        return {color: "yellow", name: "Community Leader"}
      case RankType.Moderator:
        return {color: "lightgreen", name: "Moderator"}
      case RankType.Administrator:
        return {color: "red", name: "Administrator"}
      case RankType.Developer:
        return {color: "#8d2bf0", name: "Developer"}
      case RankType.Root:
        return {color: "red", name: "~#root"}
      default:
        return {color: "lightblue", name: "Normal User"}
    }
  }

  useEffect(() => {
    function getAdminTitle() {
      switch (user.status) {
        case "AUTHENTICATED":
          return <CardHeader
              avatar={
                <Avatar src={user?.picture} sx={{flexGrow: 0, display: "inline-flex"}}/>
              }
              title={<Typography sx={{color: "#828282", fontWeight: "bold"}}>{user?.name ?? "Lucid Retro"}</Typography>}
              subheader={<Typography variant={"subtitle2"} style={{color: getRank().color}}>{getRank().name}</Typography>}
            />
        case "PENDING": return defaultHeader
        case "NOT_AUTHENTICATED": return defaultHeader
        default: return defaultHeader
      }
    }

    setSubtitle(getAdminTitle());

    if (user.status === "NOT_AUTHENTICATED" && location.pathname !== "/") {
      navigate("/")
    }

  }, [user, rank])


  const getUserBar = () => (
    <Card elevation={0} sx={{flexGrow: 1, backgroundColor: "#272727"}}>
      {subtitle}
    </Card>
  );

  const getLoginButton = () => {
    switch (user.status) {
      case "AUTHENTICATED": return <Button color="inherit" onClick={() => handleLogout(navigate('/'))}>Logout</Button>
      case "NOT_AUTHENTICATED": return <Auth
        supabaseClient={supabase}
        theme={"dark"}
        onlyThirdPartyProviders={true}
        appearance={{theme: ThemeSupa}}
        providers={["discord"]}
      />
      default: return <Auth
        supabaseClient={supabase}
        theme={"dark"}
        onlyThirdPartyProviders={true}
        appearance={{theme: ThemeSupa}}
        providers={["discord"]}
      />
    }
  };

  return (
    <Box sx={{flexGrow: 1}}>
      <AppBar position="static" sx={{height: "80px"}}>
        <Toolbar>
          {getUserBar()}
          {getLoginButton()}
        </Toolbar>
      </AppBar>
    </Box>)
}

export default AppHeader;