enum RankType {
  Restricted,
  NormalUser,
  VIPUser,
  Moderator,
  Administrator,
  Developer,
  Root
}

export default RankType;