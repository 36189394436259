import {Grid, Paper, TextField, Typography} from "@mui/material";


interface OtherCard {
  id: string;
  status: "DOING" | "DONE";
}

interface SelfCard {
  id: string;
  status: "DOING" | "DONE";
  value: string;
  onChange: (id: string) => void;
  onDelete: (id: string) => void;
}


interface Props {
  title: string;
  subtext?: string
  color?: string;
  selfCards: SelfCard[];
  otherCards: OtherCard[];
}


const RetroThoughtContainer = ({title, subtext, color, selfCards, otherCards}: Props) => {
  return (
    <Grid item wrap={"nowrap"}>
      <Paper elevation={3} sx={{
        p: 2,
        m: 2,
        maxWidth: "320px",
        minWidth: "320px",
        minHeight: "calc(100vh - 190px)",
        borderLeft: `solid ${color} 0.001em`
      }}>
        <div><Typography>{title}</Typography></div>
        <div><Typography variant={"caption"}>{subtext}</Typography></div>
        <Paper elevation={5} sx={{
          padding: 1,
          marginTop: "20px",
          borderLeft: `solid ${color} 1px`,
          borderRight: `solid ${color} 1px`
        }}>
          <TextField
            variant={"standard"}
            fullWidth
            multiline
            placeholder={"My reflection... (press enter to add)"}
            InputProps={{
              disableUnderline: true, // <== added this
            }}
          /></Paper>
      </Paper>
    </Grid>
  )
}

export default RetroThoughtContainer;