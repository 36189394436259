import {RetroObject} from "../api/actions/RetroAction";
import {Grid, Paper, Typography} from "@mui/material";
import React from "react";

interface Props {
  retro: RetroObject;
  onClick: (v: string) => void;
}

const RetroCard = ({retro, onClick}: Props) => {
  return ( <Grid item>
    <Paper
      sx={{
        width: "150px",
        padding: "3px",
        margin: "5px 0px",
        height: "75px",
        cursor: "pointer",
        '&&:hover': {backgroundColor: "rgba(150,150,150, 0.1)"}
      }}
      elevation={3}
      onClick={() => onClick(retro.id)}
    >
      <Typography variant={"caption"}>{retro.title}</Typography>
    </Paper>
  </Grid>)
}

export default RetroCard;